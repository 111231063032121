// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---narative-gatsby-theme-novela-src-pages-404-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/pages/404.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-pages-404-tsx" */),
  "component---narative-gatsby-theme-novela-src-pages-all-lessons-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/pages/all-lessons.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-pages-all-lessons-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-category-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/category.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-category-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-featured-articles-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/featuredArticles.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-featured-articles-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-portfolio-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/portfolio.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-portfolio-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-portfolios-template-tsx": () => import("./../../../../@narative/gatsby-theme-novela/src/templates/portfolios.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-portfolios-template-tsx" */)
}

