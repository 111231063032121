import prism from "./prism";

export default {
  prism,
  
  primary: "#fff",
  secondary: "#A1A1A6",
  grey: "rgba(255, 255, 255, 0.4)",
  accent: "#7047EB",
  accentGradient: "linear-gradient(135deg, #7047EB 0%, #B447EB 100%)",
  background: "#000000",
  black: "#141414",
  backgroundModal: "rgba(17, 18, 22, 0.95)",
  header: "rgba(17, 18, 22, 0.8)",
  headerLine: "rgba(255,255,255,0.24)",
  hover: "rgba(255, 255, 255, 0.07)",
  gradient:
  "linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)",
  articleText: "#A1A1A6",
  track: "rgba(255, 255, 255, 0.3)",
  progress: "#fff",
  card: "#212121",
  error: "#EE565B",
  success: "#46B17B",
  errorBackground: "rgba(238, 86, 91, 0.1)",
  horizontalRule: "rgba(255, 255, 255, 0.15)",
  inputBackground: "rgba(255, 255, 255, 0.07)",
  textTitle: "#fff",
  softShadow: "0px -8px 24px rgba(0, 0, 0, 0.3)",
  smallShadow: "none",
  modes: {
    dark: {
      primary: "#092630",
      secondary: "#394E56",
      grey: "#989FA0",
      greyLight: "#F3F3F3",
      background: "#F8F0EA",
      white: "#FFFFFF",
      black: "#092630",
      backgroundModal: "rgba(209, 232, 235, 0.98)",
      header: "rgba(248, 240, 234, 0.8)",
      headerLine: "#092630",
      accent: "#F01727",
      accentGradient: "gradient(135deg, #7047EB 0%, #B447EB 100%);",
      hover: "rgba(0, 0, 0, 0.07)",
      gradient: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
      gradientImage: "linear-gradient(180deg, rgba(209, 232, 235, 0) 0%, rgba(209, 232, 235, 0.95) 71.88%, #D1E8EB 100%)",
      articleText: "#092630",
      track: "rgba(8, 8, 11, 0.3)",
      progress: "#000",
      card: "#fff",
      error: "#EE565B",
      success: "#46B17B",
      errorBackground: "rgba(238, 86, 91, 0.1)",
      horizontalRule: "rgba(0, 0, 0, 0.15)",
      inputBackground: "rgba(0, 0, 0, 0.05)",
      textTitle: "#111111",
      smallShadow: "0px 2px 6px rgba(119, 90, 67, 0.15)",
      softShadow: "0 2px 24px 0 rgba(119, 90, 67, 0.3)",
    },
  },
};
